<template>
  <div>
    <nav :class="{ 'transparent': isTransparent, 'solid': !isTransparent }" class="navigation-bar">
      <router-link :to="{ path: '/', hash: '#inicio' }">
        <img class="logo" :src="isTransparent ? logoTransparent : logoSolid" alt="Logo">
      </router-link>
      <button class="hamburger" @click="isNavOpen = !isNavOpen"
        :class="{ 'white-lines': isTransparent, 'black-lines': !isTransparent }">
        <span></span>
        <span></span>
        <span></span>
      </button>
      <ul class="nav-links navigation-bar-links">
        <li><a href="#inicio">Inicio</a></li>
        <li><a href="#nosotros">Nosotros</a></li>
        <li><a href="#servicios">Servicios</a></li>
        <li><a href="#tragos">Carta de tragos</a></li>
        <li><a href="#galeria">Instagram</a></li>
        <li><a href="#faq-section">FAQs</a></li>
        <li><a href="#presupuesto" class="highlight-link">Pedí tu presupuesto</a></li>
      </ul>
    </nav>
    <transition name="dropdown">
      <div v-show="isNavOpen" class="dropdown">
        <ul class="dropdown-links">
          <li><a href="#inicio" @click="isNavOpen = false">Inicio</a></li>
          <li><a href="#nosotros" @click="isNavOpen = false">Nosotros</a></li>
          <li><a href="#servicios" @click="isNavOpen = false">Servicios</a></li>
          <li><a href="#tragos" @click="isNavOpen = false">Carta de tragos</a></li>
          <li><a href="#galeria" @click="isNavOpen = false">Instagram</a></li>
          <li><a href="#faq-section" @click="isNavOpen = false">FAQs</a></li>
          <li><a href="#presupuesto" @click="isNavOpen = false" class="highlight-link">Pedí tu presupuesto</a></li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'NavigationBar',
  data() {
    return {
      isTransparent: true,
      isNavOpen: false,
      logoTransparent: require('@/assets/transparent_logo.png'),
      logoSolid: require('@/assets/solid_logo.png'),
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize);
    this.handleScroll();
    this.handleResize();
  },
  methods: {
    handleScroll() {
      if (window.innerWidth >= 769) {
        this.isTransparent = window.scrollY < 50;
      } else {
        this.isTransparent = false;
      }
    },
    handleResize() {
      if (window.innerWidth >= 769) {
        this.isNavOpen = false;
      }
    },
    toggleNav() {
      this.isNavOpen = !this.isNavOpen;
    }
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('resize', this.handleResize);
  }
}
</script>

<style scoped>
.hamburger {
  display: block;
  cursor: pointer;
  background: transparent;
  border: none;
}

@media (min-width: 769px) {
  .hamburger {
    display: none;
  }
}

@media (max-width: 768px) {
  .hamburger {
    display: block;
  }

  ul {
    display: none;
  }

  .nav-links {
    display: none;
  }
}

.hamburger span {
  display: block;
  width: 33px;
  height: 4px;
  margin: 5px auto;
  background-color: #333;
}

.white-lines span {
  background-color: white;
}

.black-lines span {
  background-color: black;
}

.navigation-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 60px;
  z-index: 1000;
  transition: background-color 0.5s ease;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.navigation-bar ul {
  display: flex;
  justify-content: flex-end;
  width: auto;
  align-items: center;
}

.navigation-bar li {
  margin-right: 15px;
}

.navigation-bar a {
  text-decoration: none;
  padding: 10px;
  transition: color 0.3s ease;
  font-size: 14px;
}

@media (max-width: 768px) {
  .navigation-bar {
    background: #fff;
  }

  .navigation-bar>.navigation-bar-links {
    display: none;
  }
}

.navigation-bar a:hover {
  color: #FF00FF;
}

.transparent {
  background-color: transparent;
  box-shadow: none;
  height: 60px;
  color: white;
}

.solid {
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  height: 60px;
  color: #333;
}

.transparent a {
  color: white;
}

.solid a {
  color: #333;
}

.logo {
  height: 60px;
  margin-right: auto;
  margin-left: 20px;
}

@media screen and (max-width: 768px) {
  .logo {
    height: 40px;
    margin-left: 10px;
  }
}

nav {
  display: flex;
  flex-wrap: nowrap;
}

router-link {
  text-decoration: none;
  color: black;
}

.nav-container {
  position: fixed;
  width: 100%;
  background: white;
  display: flex;
  justify-content: space-between;
}

.dropdown {
  position: fixed;
  top: 60px;
  right: 0;
  left: 0;
  background: white;
  z-index: 999;
}

.dropdown-links {
  list-style-type: none;
  padding-left: 0;
  text-align: left;
}

.dropdown-links li {
  border-bottom: 1px solid #ddd;
  height: 60px;
  line-height: 60px;
}

.dropdown-links li:last-child {
  border-bottom: none;
}

.dropdown-menu {
  position: absolute;
}

.dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.dropdown li {
  padding: 10px;
}

.dropdown a {
  text-decoration: none;
  color: black;
  display: block;
}

@media (min-width: 769px) {
  .dropdown ul {
    display: flex;
  }
}

@media (min-width: 769px) {
  .dropdown-links {
    display: none;
  }
}

@media (max-width: 768px) {
  .nav-links {
    display: none;
  }
}

.dropdown-enter-active {
  animation: slide-down .5s ease forwards;
}

.dropdown-leave-active {
  animation: slide-up .5s ease forwards;
}

@keyframes slide-down {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

@keyframes slide-up {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-100%);
  }
}

/* Specific styles for "Pedí tu presupuesto" link */
.highlight-link {
  font-weight: bold; /* Make the text bold */
  color: #ff69b4 !important; /* Set the color to pink */
}

.highlight-link:hover {
  color: #ff1493 !important; /* Set the hover color to a darker pink */
  text-shadow: 0 0 20px rgba(239, 34, 143, 0.7); /* Add a glow effect */
}
</style>