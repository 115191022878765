<template>
  <form @submit.prevent="submitForm" class="contact-form" id="presupuesto">
    <div class="image-container">
      <img src="@/assets/vamos.png" alt="Contact Us 1" class="first-image" />
      <img src="@/assets/contacto.png" alt="Contact Us 2" class="second-image" />
    </div>
    <div class="form-header">
      <button type="button" @click="selectForm('consultas', $event)" class="neon-button">Consultas y
        comentarios</button>
      <button type="button" @click="selectForm('presupuesto', $event)" class="neon-button">Pedí tu presupuesto</button>
    </div>

    <div class="form-group">
      <!-- Fields for both "Consultas y comentarios" and "Pedí tu presupuesto" -->
      <label for="name">Nombre y Apellido:</label>
      <input type="text" id="name" v-model="contact.name" class="uniform-input" required>

      <label for="email">Email:</label>
      <input type="email" id="email" v-model="contact.email" class="uniform-input" required>

      <div v-if="selectedForm === 'presupuesto'">
        <!-- Additional fields for "Pedí tu presupuesto" -->
        <label for="phone">Teléfono:</label>
        <input type="tel" id="phone" v-model="contact.phone" class="uniform-input" required>

        <label for="eventType">Tipo de Evento:</label>
        <select id="eventType" v-model="contact.eventType" class="uniform-input" required>
          <!-- Add your event types here -->
          <option value="">Seleccione un tipo de evento</option>
          <option value="Boda">Boda</option>
          <option value="Cumpleaños">Cumpleaños</option>
          <option value="Fiesta de 15">Fiesta de 15</option>
          <option value="Empresarial">Empresarial</option>
          <option value="Otros">Otros</option>
        </select>

        <label for="guests">Número de Invitados:</label>
        <select id="guests" v-model="contact.guests" class="uniform-input" required>
          <option value="">Seleccione el número de invitados</option>
          <option value="20">20</option>
          <option value="30">30</option>
          <option value="40">40</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="150">150</option>
          <option value="200">200</option>
          <option value="250">250</option>
          <option value="Ingresar otro">Ingresar otro</option> <!-- New option added here -->
        </select>
        <div v-if="contact.guests === 'Ingresar otro'">
          <input type="text" id="customGuests" v-model="contact.customGuests" class="uniform-input"
            placeholder="Ingrese el número de invitados" @focus="clearPlaceholder" @blur="resetPlaceholder" required>
        </div>
        <label for="date">Fecha:</label>
        <input type="text" id="date" v-model="contact.date" ref="dateInput" class="uniform-input date-picker" required>
      </div>
      <label for="message">Mensaje:</label>
      <textarea id="message" v-model="contact.message" class="uniform-input" required></textarea>
    </div>

    <button :disabled="isSubmitting" type="submit">Enviar</button>
  </form>
</template>

<script>
import { nextTick } from 'vue';
import flatpickr from "flatpickr";
import "flatpickr/dist/flatpickr.css";

export default {
  data() {
    return {
      selectedForm: 'presupuesto', // Set 'presupuesto' as the default form
      contact: {
        name: '',
        email: '',
        message: '',
        phone: '',
        date: '',
        eventType: '',
        guests: '',
        customGuests: '' // New data property for custom number of guests
      },
      isSubmitting: false,
      isMounted: false, // Flag to check if component is mounted
      customGuestsPlaceholder: 'Ingrese el número de invitados' // Placeholder text for custom guests input
    };
  },
  mounted() {
    this.isMounted = true;
    if (this.selectedForm === 'presupuesto') {
      this.initializeDatepicker();
    }
    this.setTopPadding();
    window.addEventListener('resize', this.setTopPadding); // Adjust padding on window resize
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.setTopPadding); // Clean up event listener
  },
  watch: {
    selectedForm(newForm) {
      if (newForm === 'presupuesto') {
        nextTick(() => {
          this.initializeDatepicker();
        });
      }
    }
  },
  methods: {
    initializeDatepicker() {
      if (this.$refs.dateInput) {
        flatpickr(this.$refs.dateInput, {
          dateFormat: "d/m/Y", // Set the date format to dd/mm/yyyy
          disableMobile: true, // Force flatpickr to be used on mobile devices
          static: true // Add this line to prevent the positioning error
        });
      }
    },
    setTopPadding() {
      const navbar = document.querySelector('.navbar');
      if (navbar) {
        const navbarHeight = navbar.offsetHeight;
        document.querySelector('.contact-form').style.paddingTop = `${navbarHeight + 20}px`; // Add some extra space
      } else {
        document.querySelector('.contact-form').style.paddingTop = '80px'; // Default padding if navbar is not found
      }
    },
    clearPlaceholder(event) {
      event.target.placeholder = '';
    },
    resetPlaceholder(event) {
      event.target.placeholder = this.customGuestsPlaceholder;
    },
    selectForm(formType, event) {
      this.selectedForm = formType;
      event.target.blur(); // Remove focus from the button
    },
    submitForm() {
      this.isSubmitting = true;
      const formData = new FormData();
      formData.append('name', this.contact.name);
      formData.append('email', this.contact.email);
      formData.append('message', this.contact.message);

      if (this.selectedForm === 'presupuesto') {
        formData.append('phone', this.contact.phone);
        formData.append('date', this.contact.date);
        formData.append('eventType', this.contact.eventType);
        formData.append('guests', this.contact.guests === 'Ingresar otro' ? this.contact.customGuests : this.contact.guests);
      }

      fetch('/send-email.php', {
        method: 'POST',
        body: formData
      })
        .then(response => response.text())
        .then(data => {
          if (data === 'success') {
            alert('Formulario enviado!');
          } else {
            alert('Envio fallido: ' + data);
          }
          this.isSubmitting = false;
        })
        .catch(error => {
          console.error('Error:', error);
          this.isSubmitting = false;
        });
    }
  }
};
</script>

<style scoped>
.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 2em;
  color: #e40e7f;
  /* Set the text color for the entire section */
}

.form-header {
  display: flex;
  justify-content: center;
  gap: 60px;
  /* Increase the space between the buttons */
  margin-bottom: 20px;
  /* Add space below the buttons */
}

.neon-button {
  background-color: transparent;
  border: 2px solid #e40e7f;
  color: #e40e7f;
  padding: 5px 10px;
  /* Reduce the padding to make the buttons smaller */
  font-size: 1em;
  /* Reduce the font size to make the buttons smaller */
  cursor: pointer;
  text-transform: uppercase;
  position: relative;
  transition: color 0.3s, box-shadow 0.3s;
  border-radius: 25px;
  /* Make the buttons rounded */
}

.neon-button::before,
.neon-button::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  box-shadow: 0 0 10px #e40e7f, 0 0 15px #e40e7f, 0 0 20px #e40e7f, 0 0 25px #e40e7f;
  /* Reduce the shadow effect */
  opacity: 0;
  transition: opacity 0.3s;
  border-radius: 25px;
  /* Match the border radius of the button */
}

.neon-button::before {
  z-index: -1;
}

.neon-button:hover {
  color: white;
  box-shadow: 0 0 10px #e40e7f, 0 0 15px #e40e7f, 0 0 20px #e40e7f, 0 0 25px #e40e7f;
  /* Reduce the shadow effect */
}

.neon-button:hover::before,
.neon-button:hover::after {
  opacity: 1;
}

h2 {
  color: #e40e7f;
  /* Ensure the h2 element is also colored */
  text-align: center;
  /* Center the h2 element */
  margin: 20px 0;
  /* Add space above and below the h2 element */
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  /* Increase the maximum width for the form group */
  margin: 0 auto;
  /* Center the form group */
}

label,
input,
textarea,
select {
  color: #e40e7f;
  /* Set the text color for form elements */
  display: block;
  margin: 10px 0;
  /* Increase the space between form elements */
  width: 100%;
  /* Ensure all form elements have the same width */
}

.uniform-input {
  width: 100%;
  /* Ensure all form elements have the same width */
  padding: 10px;
  border: 2px solid #e40e7f;
  border-radius: 5px;
  background-color: white;
  /* Set the background color to white */
  color: black;
  /* Set the text color inside the input fields to black */
  box-sizing: border-box;
  /* Include padding and border in the element's total width and height */
}

input::placeholder,
textarea::placeholder {
  color: black;
  /* Set the placeholder color to black */
}

button {
  background-color: transparent;
  border: 2px solid #e40e7f;
  color: #e40e7f;
  padding: 10px 20px;
  font-size: 1.2em;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.3s, box-shadow 0.3s;
  border-radius: 25px;
  margin-top: 20px;
}

button:hover {
  color: white;
  box-shadow: 0 0 10px #e40e7f, 0 0 15px #e40e7f, 0 0 20px #e40e7f, 0 0 25px #e40e7f;
}

/* Remove focus outline after button click */
button:focus {
  outline: none;
}

.image-container {
  padding-bottom: 10%;
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto 1em auto;
  text-align: center;
}

.first-image {
  display: block;
  width: 100%;
  height: auto;
}

.second-image {
  position: absolute;
  top: 55%;
  left: 46%;
  transform: translateX(-50%);
  width: 80%;
  height: auto;
}

/* Add specific styles for the date picker input */
.date-picker {
  -webkit-appearance: none;
  /* Remove default styling on iOS */
  -moz-appearance: none;
  /* Remove default styling on Firefox */
  appearance: none;
  /* Remove default styling on other browsers */
}

.date-picker::-webkit-inner-spin-button,
.date-picker::-webkit-calendar-picker-indicator {
  display: none;
  /* Remove default calendar icon */
}

/* Add media query for mobile devices */
@media (max-width: 600px) {
  .uniform-input {
    border: 2px solid #e40e7f;
    /* Ensure the pink border is applied on mobile */
  }
}
</style>