<template>
  <div id="faq-section">
    <div class="image-container">
      <img src="@/assets/faqs.png" alt="FAQs" class="faq-title-image" />
    </div>
    <div v-for="(faq, index) in faqs" :key="index" class="faq-item">
      <div @click="toggleAnswer(index)" class="faq-question">
        <i :class="['fas', activeQuestion === index ? 'fa-chevron-up' : 'fa-chevron-down', 'arrow']"></i>
        <span class="question-text">{{ faq.question }}</span>
      </div>
      <transition
        name="faq"
        @before-enter="beforeEnter"
        @enter="enter"
        @leave="leave"
      >
        <div v-if="activeQuestion === index" class="faq-answer">
          {{ faq.answer }}
        </div>
      </transition>
      <div class="faq-divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      faqs: [
        {
          question: '¿Tengo que encargarme de algo extra?',
          answer: 'No, nosotros nos encargamos de todo. Llevamos la cristalería, el hielo y todo lo necesario para brindar el servicio.',
        },
        {
          question: '¿Cuánto tiempo dura el servicio?',
          answer: 'El servicio está presupuestado para 6 horas, comenzando al inicio del evento y finalizando tras 6 horas continuas. Si deseas extender el horario, podemos cotizar el tiempo adicional.',
        },
        {
          question: '¿Cómo realizo la seña?',
          answer: 'Para confirmar y congelar el presupuesto, solicitamos un depósito bancario del 10% de la cotización enviada.',
        },
        {
          question: '¿Cuándo debo abonar el servicio?',
          answer: 'Solicitamos cancelar el saldo pendiente la semana previa al evento.',
        },
        {
          question: '¡Todavía no tengo clara la cantidad de personas!',
          answer: 'La semana previa al evento afinamos la cantidad de personas y ajustamos la cotización en base a ello.',
        },
        {
          question: '¿Incluyen cervezas, whisky y refrescos?',
          answer: 'No los incluimos en la canilla libre, pero podemos llevarlos y servirlos desde la barra. El costo será a cargo del cliente.',
        },
        {
          question: '¿Cuántos barmans asisten?',
          answer: 'Depende del tipo de evento. Aproximadamente, contamos con un barman por cada 40 personas.',
        },
        {
          question: '¿Podemos incluir algún trago especial?',
          answer: 'Sí, podemos agregar cualquier trago que desees. El costo adicional será a cargo del cliente.',
        },
        {
          question: '¿Cuánto tiempo necesitan para armar?',
          answer: 'Nos gustaría llegar una hora antes del horario al que están citados los invitados para prepararnos adecuadamente.',
        },
        {
          question: '¿Qué espacio necesitan?',
          answer: 'Necesitamos un espacio de 2 metros y medio de largo por 1 metro de ancho.',
        },
        {
          question: '¿Puedo elegir el modelo de la barra?',
          answer: 'Sí, puedes elegir el modelo de la barra según disponibilidad.',
        },
      ],
      activeQuestion: null,
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeQuestion = this.activeQuestion === index ? null : index;
    },
    beforeEnter(el) {
      el.style.height = '0';
    },
    enter(el) {
      el.style.height = el.scrollHeight + 'px';
    },
    leave(el) {
      el.style.height = el.scrollHeight + 'px';
      el.offsetHeight; // force reflow
      el.style.height = '0';
    },
  },
};
</script>

<style scoped>
#faq-section {
  background-color: white !important; /* Ensure background color is white */
  color: black !important; /* Ensure text color is black */
  margin: 0; /* Ensure no margin */
  border: none; /* Ensure no border */
  text-align: center; /* Center all text */
  padding: 0 0 1em 0;
  border-bottom: none; /* Ensure there is no border at the bottom */
  box-shadow: none; /* Ensure there is no box-shadow */
}

.image-container {
  text-align: center;
  margin-bottom: 1em;
  padding: 5%;
}

.faq-title-image {
  width: 100%;
  max-width: 400px;
  height: auto;
}

.faq-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: none; /* Remove the border from the bottom */
}

.faq-question {
  padding: 0.5em 0; /* Adjust padding to reduce space on top and bottom */
  width: 80%; /* Adjust width to control the length of the question */
  font-size: 1.2em; /* Make the question text larger */
  font-weight: bold; /* Make the question text bold */
  border-bottom: none; /* Ensure no border at the bottom */
  cursor: pointer; /* Change cursor to pointer to indicate it's clickable */
  display: flex;
  align-items: center;
  margin-top: 0.5em; /* Reduce space between the top of the question and the previous divider */
  margin-bottom: 1.5em;
}

.arrow {
  margin-right: 0.5em; /* Space between arrow and question text */
  transition: transform 0.3s ease; /* Smooth rotation transition */
  display: inline-block; /* Ensure the arrow is inline with the text */
}

.expanded {
  transform: rotate(180deg); /* Rotate arrow to point up when expanded */
}

.faq-answer {
  overflow: hidden;
  transition: height 0.3s ease;
  padding: 0.5em 0; /* Adjust padding to control the spacing */
  width: 80%; /* Adjust width to control the length of the answer */
  font-size: 1em; /* Make the answer text smaller */
  border-bottom: none; /* Ensure no border at the bottom */
  text-align: left; /* Align text to the left */
  margin-left: 3.0em; /* Align answers with questions */
  color: rgba(117, 105, 117, 1); /* Change color of the answers to a more faded pink */
}

.faq-divider {
  width: 80%; /* Set the width to 80% */
  margin: 0; /* Remove default margin */
  border-bottom: 1px solid #ddd; /* Style the divider line */
}

.faq-item:not(:last-child) .faq-divider {
  margin-bottom: 1em; /* Space between the bottom of the divider and the next question */
}

.faq-item:not(:first-child) .faq-question {
  margin-top: 1em; /* Space between the top of the question and the previous divider */
}

/* Add padding to the top of the FAQ section on mobile devices */
@media (max-width: 768px) {
  #faq-section {
    padding-top: 4em; /* Adjust this value based on the height of your navbar */
  }
}
</style>