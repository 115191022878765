<template>
  <section id="servicios" class="service-options">
    <div class="image-container">
      <img :src="firstServiceImage" alt="First Service Image" class="first-image" />
      <img :src="secondServiceImage" alt="Second Service Image" class="second-image" />
    </div>
    <div class="services">
      <div class="service" v-for="service in services" :key="service.id">
        <div class="service-icon-container">
          <img :src="service.icon" alt="Service Icon" :class="{'service-icon': true, 'truck-icon': service.id === 2}">
        </div>
        <p>{{ service.description }}</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ServiceOptions',
  data() {
    return {
      services: [
        { id: 1, icon: require('@/assets/cocktail.png'), description: 'Cristalería' },
        { id: 2, icon: require('@/assets/truck.png'), description: 'Equipamiento y Traslado' },
        { id: 3, icon: require('@/assets/clock.png'), description: 'Barra libre' },
        { id: 4, icon: require('@/assets/avatar.png'), description: 'Cantidad de barmans apropiada' },
      ],
      firstServiceImage: require('@/assets/titulo_incluye.png'),
      secondServiceImage: require('@/assets/servicio.png'),
    }
  }
}
</script>

<style scoped>
.service-options {
  padding-top: 50px; /* adjust this value as needed */
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.image-container {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

.first-image {
  display: block;
  width: 100%;
  height: auto;
}

.second-image {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;
  height: auto;
}

.services {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 80px;
}

.service {
  flex: 0 0 45%; /* reduce the base size to 45% of the container */
  box-sizing: border-box; /* include padding and border in the element's total width and height */
  margin: 1px;
  margin-top: 30px; /* reduce the top margin */
  color: white;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.service-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.navbar {
  position: relative;
  z-index: 1;
}

.service-icon-container {
  width: 100px;
  /* adjust this value as needed */
  margin-bottom: 10px;
  margin-top: 80;
  height: 100px;
  overflow: hidden;
  /* this makes the content of the div stay within the circular shape */
}

.service-icon {
  width: 100%;
  height: auto;
}

.truck-icon {
  position: relative;
  left: -5px; /* adjust this value as needed */
  top: 10px; /* adjust this value as needed */
}

.service p {
  margin-top: 10px;
  /* adjust this value as needed */
}

.service h3 {
  color: #333;
}

.service-options {
  background-image: require('@/assets/images/background2.jpeg');
}
</style>