<template>
  <div id="galeria" class="image-gallery">
    <div class="title-container">
      <img src="@/assets/insta.png" alt="Icon" class="title-icon" />
      <h2 class="gallery-title">Seguinos en Instagram!</h2>
    </div>
    <div class="widget-wrapper">
      <iframe ref="widget" src="//lightwidget.com/widgets/1ff4551c4332514cb262ca7c72026545.html" scrolling="no"
        allowtransparency="true" class="lightwidget-widget"
        style="width:100%; height:600px; border:0; overflow:hidden;"></iframe>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.loadWidgetScript();
  },
  methods: {
    loadWidgetScript() {
      const script = document.createElement('script');
      script.src = 'https://cdn.lightwidget.com/widgets/lightwidget.js';
      script.onload = () => {
        window.LightWidget && window.LightWidget.init();
      };
      document.body.appendChild(script);
    }
  }
};
</script>

<style scoped>
.image-gallery {
  padding: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.title-container {
  display: flex;
  align-items: center;
  margin-bottom: 1em;
}

.title-icon {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.gallery-title {
  font-size: 2em;
  color: white;
}

.widget-wrapper {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensure content does not overflow */
}

.lightwidget-widget {
  width: 100%;
  height: 800px; /* Increase the height */
  border: 0;
  overflow: hidden;
}

/* Add media query for mobile devices */
@media (max-width: 768px) {
  .widget-wrapper {
    width: 100%;
    justify-content: center; /* Center the iframe horizontally */
    align-items: center; /* Center the iframe vertically */
  }

  .lightwidget-widget {
    width: 100%; /* Make the iframe take full width */
    height: 1500px; /* Further increase the height for better visibility on mobile */
  }
}
</style>